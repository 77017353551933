import { TaxedMoneyRange, TaxedMoney } from "../Types/Common";

export const formatPrice = (
  amount: number,
  currency: string,
  locale: string = "en-US"
) => {
  let _locale =
    locale.length === 2 ? `${locale.toLowerCase()}-${locale}` : locale;

  if (locale === "EN") {
    _locale = "en-US";
  }

  const formatter = new Intl.NumberFormat(_locale, {
    style: "currency",
    currency,
  });

  return formatter.format(amount);
};

export const formatPriceRange = (
  priceRange: TaxedMoneyRange,
  locale?: string
): string => {
  if (
    (priceRange.start &&
      priceRange.stop &&
      priceRange.start.gross.amount == priceRange.stop.gross.amount) ||
    (priceRange.start && !priceRange.stop) ||
    (priceRange.stop && !priceRange.start)
  ) {
    const amount = (priceRange.start || priceRange.stop) as TaxedMoney;

    return formatPrice(amount.gross.amount, amount.currency, locale);
  }

  const start = priceRange.start as TaxedMoney;
  const stop = priceRange.stop as TaxedMoney;

  return `${formatPrice(
    start.gross.amount,
    start.currency,
    locale
  )} - ${formatPrice(stop.gross.amount, stop.currency, locale)}`;
};
