import { ApiCollectionResponse } from "../Types/Api";

/**
 * Transforms the collection returned by the API in the form of
 * {
 *  ...
 *  edges: {
 *    node: T,
 *    cursor: string
 *  }[]
 * }
 * to a simple array of T
 */
export function transformCollection<T>(
  response: ApiCollectionResponse<T>,
  itemsKey: string
): T[] {
  return response[itemsKey].edges.map((edge) => ({
    ...edge.node,
    _cursor: edge.cursor,
  }));
}
