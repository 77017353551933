import { Client } from "../Client";
import type Pusher from "pusher-js";
import {
  Video,
  VideoEvent,
  VideoInstance,
  VideoStats,
  VideoStatus,
} from "../Types/Video";
import { createEventBroadcaster } from "./CreateEventBroadcaster";
import {
  ApiWSComment,
  ApiWSSingleVideo,
  ApiWSVideo,
  ApiWSVideoPopup,
  SubscribersCounter, VideoVisibleProducts,
} from "../Types/Api";
import { CommentInstance } from "../Types/Chat";
import { createCommentInstanceTransformer } from "./CreateCommentInstance";
import { IdType, Image } from "../Types/Common";
import { createProductInstanceTransformer } from "./CreateProductInstance";
import { VideoPopup } from "../Types/Popups";
import { PopupUser } from "../Types/User";

export const createVideoInstanceTransformer = (
  client: Client,
  pusher?: Pusher,
  enableLiveChat?: boolean,
  enableLiveUpdates?: boolean
) => {
  const createCommentInstance = createCommentInstanceTransformer(client);
  const createProductInstance = createProductInstanceTransformer(client);

  return (data: Video): VideoInstance => {
    let liveChatEnabled = false;
    let liveUpdatesEnabled = false;

    const instance = {
      ...data,
      videoProducts: data.videoProducts
        ? data.videoProducts.map((videoProduct) => ({
            product: createProductInstance(videoProduct.product),
            isVisible: videoProduct.isVisible,
            startTime: videoProduct.startTime,
            endTime: videoProduct.endTime,
          }))
        : undefined,
      added: data.added ? new Date(data.added) : undefined,
      get muxId(): IdType | null {
        if (!data.resultFile) {
          return null;
        }

        return data.resultFile.slice(data.resultFile.lastIndexOf("/") + 1);
      },
      // activePopup: data.activePopup,
      comments: data.comments
        ? data.comments.map((comment) => {
            comment = createCommentInstance(comment);
            if (comment.parentId && comment.parentId != comment.globalId) {
              const commentParents = data.comments?.findIndex(
                (v) => v.globalId == comment.parentId
              );
              if (commentParents > -1) {
                comment.parent = createCommentInstance(
                  data.comments[commentParents]
                );
              } else comment.parent = null;
            }
            return comment;
          })
        : [],
      ...createEventBroadcaster<VideoEvent>(),
      async sendChatMessage(message: string, nick?: string) {
        if (!this.globalId) {
          return;
        }

        return await client.sendChatMessage(this.globalId, message, nick);
      },
      async likeVideo() {
        return await client.likeVideo(this.id);
      },
      async dislikeVideo() {
        return await client.dislikeVideo(this.id);
      },
      async addVideoView() {
        if (!this.globalId) {
          return undefined;
        }

        const views = await client.addVideoView(this.globalId);

        if (!views) {
          return undefined;
        }

        if (!this.videoStats) {
          this.videoStats = {};
        }

        this.videoStats.views = views;

        return views;
      },
      enableLiveChat() {
        if (liveChatEnabled) {
          return;
        }

        if (!pusher) {
          pusher = client._initPusher();

          if (!pusher) {
            throw "[LiveMarket SDK] Pusher is required to enable chat.";
          }
        }

        if (!this.comments) {
          this.comments = [];
        }

        if (!this.globalId) {
          console.warn(
            "[LiveMarket SDK] This video does not have a globalId. Live chat will not be enabled."
          );
          return;
        }

        const handleComment = (comment: ApiWSComment) => {
          const idx = this.comments?.findIndex((v) => v.id === comment.id);
          const created = idx === -1;
          const commentInstance = createCommentInstance({
            id: comment.id,
            globalId: comment.globalId,
            comment: comment.message,
            submitDate: comment.submitDate,
            isRemoved: comment.isRemoved,
            fromModerator: comment.fromModerator,
            parentId: comment.parentId,
            user: {
              id: comment.user.id,
              nick: comment.user.name,
              avatar: {
                url: comment.user.avatar,
              },
              fullName: comment.user.fullName,
            },
          });
          if (created) {
            this.comments?.push(commentInstance);
            this._dispatchEvent<CommentInstance>(
              "new-chat-message",
              commentInstance
            );
          } else {
            if (idx >= 0) {
              this.comments?.splice(idx, 1, commentInstance);
            }
            this._dispatchEvent<CommentInstance>(
              "updated-chat-message",
              commentInstance
            );
          }
        };

        const channel = pusher.subscribe(
          `public-video-comments-${this.globalId}`
        );
        channel.bind("comment-created", handleComment);
        channel.bind("comment-updated", handleComment);

        liveChatEnabled = true;
      },
      enableLiveUpdates() {
        if (liveUpdatesEnabled) {
          return;
        }

        if (!pusher) {
          pusher = client._initPusher();

          if (!pusher) {
            throw "[LiveMarket SDK] Pusher is required to enable video updates.";
          }
        }

        if (!this.globalId) {
          console.warn(
            "[LiveMarket SDK] This video does not have a globalId. Video updates will not be enabled."
          );
          return;
        }

        const handleVideoUpdate = (video: ApiWSVideo) => {
          const { title, author, status, startTime, imageUrl } = video.video;

          this.title = title;
          this.author = {
            ...this.author,
            firstName: author.name,
          };
          this.status = status;
          this.videoStartTime = startTime ? new Date(startTime) : undefined;
          this.videoImage = {
            url: imageUrl,
          };
          this.videoStats = video.counter;
        };

        const handleVideoStatsChange = (video: ApiWSVideo) => {
          handleVideoUpdate(video);

          this._dispatchEvent<VideoStats | undefined>(
            "video-stats-change",
            this.videoStats
          );
        };

        const handleVideoStatusChange = (video: ApiWSSingleVideo) => {
          handleVideoUpdate(<ApiWSVideo>{ video: video, counter: {} });
          this._dispatchEvent<VideoStatus | undefined>(
            "video-status-change",
            this.status
          );
        };

        const handleVideoActivePopupChange = (videoPopup: ApiWSVideoPopup) => {
          this.activePopup = <VideoPopup>{ ...this.activePopup, ...videoPopup };

          this._dispatchEvent<VideoPopup | undefined>(
            "video-popup-send",
            this.activePopup
          );
        };

        const handleSubscriptionChange = (data: SubscribersCounter) => {
          this.subscribersCounter = data.subscription_count;
          this._dispatchEvent<SubscribersCounter | undefined>(
            "video-live-count",
            data
          );
        };

        const handleVideoProductShow = (data: VideoVisibleProducts) => {
          this._dispatchEvent<VideoVisibleProducts | undefined>(
              "video-product-show",
              data
          );
        };

        const channel = pusher.subscribe(`public-video-${this.globalId}`);
        channel.bind("video-stats-changed", handleVideoStatsChange);
        channel.bind("video-live-started", handleVideoStatusChange);
        channel.bind("video-live-published", handleVideoStatusChange);
        channel.bind("video-popup-sent", handleVideoActivePopupChange);
        channel.bind("video-product-show", handleVideoProductShow);
        channel.bind("pusher:subscription_count", handleSubscriptionChange);

        liveUpdatesEnabled = true;
      },
    };

    if (enableLiveChat) {
      instance.enableLiveChat();
    }

    if (enableLiveUpdates) {
      instance.enableLiveUpdates();
    }

    return instance;
  };
};
