export const TEMPORARY_USER_FRAGMENT = `
  fragment temporaryUser on TemporaryUserType {
    id
    email
    nick
    temporaryToken
  }
`;

export const USER_FRAGMENT = `
  fragment user on User {
    id
    firstName
    lastName
    nick
    avatar {
      url
      alt
    }
  }
`;

export const TEMPORARY_USER_CREATE = `
  mutation temporaryUserCreate {
    temporaryUserCreate {
      temporaryUser {
        ...temporaryUser
      }
    }
  }
  ${TEMPORARY_USER_FRAGMENT}
`;

export const TEMPORARY_USER_UPDATE = `
  mutation temporaryUserUpdate($nick: String!) {
    temporaryUserUpdate(input: {
      nick: $nick
    }) {
      temporaryUser {
        ...temporaryUser
      }
    }
  }
  ${TEMPORARY_USER_FRAGMENT}
`;
