import { USER_FRAGMENT } from "./User";

export const COMMENT_FRAGMENT = `
fragment comment on CommentType {
  id
  globalId
  parentId
  fromModerator
  user {
    ...user
  }
  comment
  submitDate
  isRemoved
}
`;

export const CREATE_COMMENT = `
mutation createComment($videoId: ID!, $comment: String!){
  createComment(
    input: {
      objectPk: $videoId,
      comment: $comment
    }
  ) {
    comment {
      ...comment
    }
  }
}
${USER_FRAGMENT}
${COMMENT_FRAGMENT}
`;
