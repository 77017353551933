import { ClientAdapterInterface } from "./Interface/ClientAdapterInterface";
import { ClientEvent, ClientOptions, FetchVideoOptions } from "./Types/Client";
import { Order } from "./Types/Query";
import {
  Product,
  ProductFilter,
  ProductInstance,
  ProductOrderField,
} from "./Types/Product";
import type Pusher from "pusher-js";
import { Query } from "./Query";
import {
  ApiCollectionResponse,
  ApiItemResponse,
  ApiWSVideo,
  CreateCommentResponse,
  DislikeVideoResponse,
  LikeVideoResponse,
  TemporaryUserCreateResponse,
  TemporaryUserUpdateResponse,
  VideoViewAddResponse,
} from "./Types/Api";
import { PRODUCT, PRODUCTS } from "./GraphQL/Product";
import { IdType } from "./Types/Common";
import {
  Video,
  VideoFilter,
  VideoInstance,
  VideoOrderField,
} from "./Types/Video";
import {
  DISLIKE_VIDEO,
  LIKE_VIDEO,
  VIDEO,
  VIDEOS,
  VIDEO_VIEW_ADD,
} from "./GraphQL/Video";
import { TemporaryUser } from "./Types/User";
import { TEMPORARY_USER_CREATE, TEMPORARY_USER_UPDATE } from "./GraphQL/User";
import { createVideoInstanceTransformer } from "./Transformer/CreateVideoInstanceTransformer";
import { createCommentInstanceTransformer } from "./Transformer/CreateCommentInstance";
import { CREATE_COMMENT } from "./GraphQL/Chat";
import { Comment, CommentInstance } from "./Types/Chat";
import { createProductInstanceTransformer } from "./Transformer/CreateProductInstance";
import { createEventBroadcaster } from "./Transformer/CreateEventBroadcaster";
import { EventBroadcaster } from "./Types/EventBroadcaster";

export class Client {
  private token: string;
  private adapter: ClientAdapterInterface;
  private pusher?: Pusher;
  private pusherKey?: string;
  private language: string;
  private _user?: TemporaryUser;

  private _liveUpdatesEnabled = false;
  private _eventBroadcaster: EventBroadcaster<ClientEvent>;

  private createCommentInstance: (data: Comment) => CommentInstance;

  public constructor(options: ClientOptions) {
    this.token = options.token;
    this.adapter = options.adapter;
    this.pusherKey = options.pusherKey;
    this.language = options.language || "EN";
    this._eventBroadcaster = createEventBroadcaster<ClientEvent>();

    if (options.pusher) {
      this._initPusher(options.pusher);
    }

    if (options.liveUpdates) {
      this.enableLiveUpdates();
    }

    this.createCommentInstance = createCommentInstanceTransformer(this);
  }

  public setLanguage(language: string) {
    this.language = language;
  }

  public getLanguage(): string {
    return this.language;
  }

  public get user(): TemporaryUser | null {
    if (!this._user) {
      return null;
    }

    return JSON.parse(JSON.stringify(this._user));
  }

  public get eventBroadcaster(): EventBroadcaster<ClientEvent> {
    return this._eventBroadcaster;
  }

  public async fetchProducts(
    filter: ProductFilter = {},
    order: Order<ProductOrderField> = {
      field: "PUBLISHED",
      direction: "DESC",
    },
    count: number = 20,
    after: string | null = null
  ): Promise<Query<Product, ProductInstance> | undefined> {
    if (!this._user) {
      await this._createTemporaryUser();
    }

    const variables = {
      filter,
      order,
      after,
      first: count,
      language: this.language,
    };

    const response = await this.adapter.query<ApiCollectionResponse<Product>>(
      PRODUCTS,
      variables
    );

    if (!response) {
      return undefined;
    }

    return new Query<Product, ProductInstance>(
      response,
      "products",
      PRODUCTS,
      variables,
      this.adapter,
      createProductInstanceTransformer(this)
    );
  }

  public async fetchProductById(
    id: IdType
  ): Promise<ProductInstance | undefined> {
    if (!this._user) {
      await this._createTemporaryUser();
    }

    const response = await this.adapter.query<ApiItemResponse<Product>>(
      PRODUCT,
      { id, language: this.language }
    );

    if (!response) {
      return undefined;
    }

    const createProductInstance = createProductInstanceTransformer(this);

    return createProductInstance(response.product);
  }

  public async fetchProductBySlug(
    slug: string
  ): Promise<ProductInstance | undefined> {
    if (!this._user) {
      await this._createTemporaryUser();
    }

    const response = await this.adapter.query<ApiItemResponse<Product>>(
      PRODUCT,
      { slug, language: this.language }
    );

    if (!response) {
      return undefined;
    }

    const createProductInstance = createProductInstanceTransformer(this);

    return createProductInstance(response.product);
  }

  public async fetchVideos(
    filter: VideoFilter = {},
    order: Order<VideoOrderField> = {
      field: "ADDED",
      direction: "DESC",
    },
    withProducts: boolean = false,
    count: number = 20,
    after: string | null = null
  ): Promise<Query<Video, VideoInstance> | undefined> {
    if (!this._user) {
      await this._createTemporaryUser();
    }

    const variables = {
      filter,
      order,
      after,
      first: count,
      withProducts,
      language: this.language,
    };

    const response = await this.adapter.query<ApiCollectionResponse<Video>>(
      VIDEOS,
      variables
    );

    if (!response) {
      return undefined;
    }

    return new Query<Video, VideoInstance>(
      response,
      "videos",
      VIDEOS,
      variables,
      this.adapter,
      createVideoInstanceTransformer(this)
    );
  }

  public async fetchVideo(
    globalId: IdType,
    options?: Partial<FetchVideoOptions>
  ): Promise<VideoInstance | undefined> {
    if (!this._user) {
      await this._createTemporaryUser();
    }

    const _options = this._resolveFetchVideosOptions(options);

    const response = await this.adapter.query<ApiItemResponse<Video>>(VIDEO, {
      id: globalId,
      withProducts: _options.withProducts,
      withComments: _options.withComments,
      language: this.language,
    });

    if (!response || !response.videoByGlobalId) {
      return undefined;
    }

    const createVideoInstance = createVideoInstanceTransformer(
      this,
      this.pusher,
      _options.liveChat,
      _options.liveUpdates
    );

    const instance = createVideoInstance(response.videoByGlobalId);

    await instance.addVideoView();

    return instance;
  }

  public async addVideoView(
    videoGlobalId: IdType
  ): Promise<number | undefined> {
    if (!this._user) {
      await this._createTemporaryUser();
    }

    const response = await this.adapter.mutate<VideoViewAddResponse>(
      VIDEO_VIEW_ADD,
      {
        videoId: videoGlobalId,
      }
    );

    if (!response?.videoViewAdd?.video?.videoStats?.views) {
      return undefined;
    }

    return response.videoViewAdd.video.videoStats.views;
  }

  public async likeVideo(id: IdType): Promise<boolean> {
    if (!this.user) {
      await this._createTemporaryUser();
    }

    const response = await this.adapter.mutate<LikeVideoResponse>(LIKE_VIDEO, {
      id,
    });

    if (!response) {
      return false;
    }

    return response.likeVideo.video.isLiked || false;
  }

  public async dislikeVideo(id: IdType): Promise<boolean> {
    if (!this.user) {
      await this._createTemporaryUser();
    }

    const response = await this.adapter.mutate<DislikeVideoResponse>(
      DISLIKE_VIDEO,
      { id }
    );

    if (!response) {
      return false;
    }

    return response.dislikeVideo.video.isLiked || false;
  }

  public async sendChatMessage(
    videoGlobalId: IdType,
    message: string,
    nick?: string
  ): Promise<CommentInstance | undefined> {
    if (!this._user) {
      this._createTemporaryUser();
    }

    if (nick && nick !== this.user?.nick) {
      await this.setUserNick(nick);
    }

    const response = await this.adapter.mutate<CreateCommentResponse>(
      CREATE_COMMENT,
      {
        videoId: videoGlobalId,
        comment: message,
      }
    );

    if (!response) {
      return;
    }

    return this.createCommentInstance(response.createComment.comment);
  }

  public async setUserNick(nick: string) {
    if (!this._user) {
      await this._createTemporaryUser();
    }

    const response = await this.adapter.mutate<TemporaryUserUpdateResponse>(
      TEMPORARY_USER_UPDATE,
      {
        nick,
      }
    );

    if (
      !response ||
      !response.temporaryUserUpdate.temporaryUser.temporaryToken
    ) {
      throw "[LiveMarket SDK] Failed to set user nick!";
    }

    this._user = response.temporaryUserUpdate.temporaryUser;
    this.adapter.setUser(this._user);
  }

  public enableLiveUpdates() {
    if (this._liveUpdatesEnabled) {
      return;
    }

    if (!this.pusher) {
      throw "[LiveMarketSDK] Cannot enable live updates: Pusher is not defined.";
    }

    const channel = this.pusher.subscribe("public-home");
    channel.bind("video-published", (video: ApiWSVideo) => {
      this.eventBroadcaster._dispatchEvent("video-published", {
        id: video.video.id,
      });
    });

    channel.bind("video-live-started", (video: ApiWSVideo) => {
      this.eventBroadcaster._dispatchEvent("video-live-started", {
        id: video.video.id,
      });
    });

    this._liveUpdatesEnabled = true;
  }

  public _initPusher(pusher?: any): Pusher | undefined {
    if (this.pusher) {
      return this.pusher;
    }

    const Pusher: any =
      pusher || (window as any).Pusher || (window as any).pusher;

    if (!pusher) {
      return undefined;
    }

    this.pusher = new Pusher(this.pusherKey, {
      cluster: "eu",
    });
    return this.pusher as Pusher;
  }

  private async _createTemporaryUser() {
    const response = await this.adapter.mutate<TemporaryUserCreateResponse>(
      TEMPORARY_USER_CREATE,
      {}
    );

    if (
      !response ||
      !response.temporaryUserCreate.temporaryUser.temporaryToken
    ) {
      throw "[LiveMarket SDK] Failed to create temporary user!";
    }

    this._user = response.temporaryUserCreate.temporaryUser;
    this.adapter.setUser(this._user);
  }

  private _resolveFetchVideosOptions(
    options: Partial<FetchVideoOptions> = {}
  ): FetchVideoOptions {
    return {
      withProducts:
        typeof options.withProducts !== "undefined"
          ? options.withProducts
          : true,
      withComments:
        typeof options.withComments !== "undefined"
          ? options.withComments
          : true,
      liveChat:
        typeof options.liveChat !== "undefined" ? options.liveChat : true,
      liveUpdates:
        typeof options.liveUpdates !== "undefined"
          ? options.liveUpdates
          : false,
    };
  }
}
