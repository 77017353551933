import { ProductVariant, ProductVariantInstance } from "../Types/Product";

export const createProductVariantInstance = (
  data: ProductVariant
): ProductVariantInstance => {
  return {
    ...data,
    get displayName() {
      return this.translation?.name || this.name;
    },
  };
};
