import { Client } from "../Client";
import { Comment, CommentInstance } from "../Types/Chat";

export const createCommentInstanceTransformer = (
  client: Client
): ((data: Comment) => CommentInstance) => {
  return (data: Comment) => ({
    ...data,
    get authorName() {
      const user = data.user;
      if(!user) {
        return 'Unknown User';
      }
      if(user.firstName){
        return `${user.firstName} ${user.lastName}`;
      }
      return (data.fromModerator ? user.fullName : false) || user.nick || 'Unknown User';
    },
    submitDate: new Date(data.submitDate),
    isMine(): boolean {
      if (!this.user || !client.user) {
        return false;
      }

      return client.user.id == this.user.id;
    },
  });
};
