import { ClientAdapterInterface } from "./Interface/ClientAdapterInterface";
import { transformCollection } from "./Transformer/CollectionTransformer";
import { ApiCollectionResponse } from "./Types/Api";
import { PageInfo } from "./Types/Query";

/**
 * Holds a collection of items fetched from the API.
 */
export class Query<T, I extends T = T> implements Iterable<I> {
  public items: I[];
  public pageInfo: PageInfo;
  public totalCount: number;

  private query: string;
  private variables: Record<string, any>;
  private itemsKey: string;
  private adapter: ClientAdapterInterface;
  private createInstance?: (data: T) => I;

  private _isFetching: boolean = false;

  public constructor(
    response: ApiCollectionResponse<T>,
    itemsKey: string,
    query: string,
    variables: Record<string, any>,
    adapter: ClientAdapterInterface,
    createInstance?: (data: T) => I
  ) {
    this.itemsKey = itemsKey;
    this.createInstance = createInstance;
    this.items = this.transformCollection(response);
    this.pageInfo = response[itemsKey].pageInfo;
    this.totalCount = response[itemsKey].totalCount || 0;
    this.query = query;
    this.variables = variables;
    this.adapter = adapter;
  }

  public [Symbol.iterator]() {
    let index = 0;

    return {
      next: () => {
        return {
          done: index >= this.items.length,
          value: this.items[index++],
        };
      },
    };
  }

  public get hasNextPage(): boolean {
    return this.pageInfo.hasNextPage;
  }

  public get isFetching(): boolean {
    return this._isFetching;
  }

  public async fetchMore(): Promise<Query<T, I> | false> {
    if (!this.pageInfo.hasNextPage || this.isFetching) {
      return false;
    }

    this._isFetching = true;

    const response = await this.adapter.query<ApiCollectionResponse<T>>(
      this.query,
      {
        ...this.variables,
        after: this.pageInfo.endCursor,
      }
    );

    if (!response) {
      return false;
    }

    this.items = [...this.items, ...this.transformCollection(response)];
    this.pageInfo = response[this.itemsKey].pageInfo;
    this.totalCount = response[this.itemsKey].totalCount || 0;

    this._isFetching = false;

    return this;
  }

  private transformCollection(response: ApiCollectionResponse<T>) {
    let items: T[] | I[] = transformCollection(response, this.itemsKey);

    if (typeof this.createInstance !== "undefined") {
      // @ts-ignore
      items = items.map((item) => this.createInstance(item));
    }

    return items as I[];
  }
}
