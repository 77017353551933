import { ClientAdapterInterface } from "../Interface/ClientAdapterInterface";
import {
  ClientAdapterOptions,
  ClientAdapterRequestOptions,
} from "../Types/ClientAdapter";
import { TemporaryUser } from "../Types/User";

export class BaseClientAdapter implements ClientAdapterInterface {
  protected url: string;
  protected user?: TemporaryUser;

  constructor(options?: ClientAdapterOptions) {
    this.url = options?.url || "https://live.market/graphql/";
  }

  query<T>(
    query: string,
    variables: Record<string, any>,
    options?: ClientAdapterRequestOptions
  ): Promise<T | undefined> {
    throw "[LiveMarket SDK] 'query' method is not implemented.";
  }

  mutate<T>(
    query: string,
    variables: Record<string, any>,
    options?: ClientAdapterRequestOptions
  ): Promise<T | undefined> {
    throw "[LiveMarket SDK] 'mutate' method is not implemented";
  }

  setUser(temporaryUser: TemporaryUser): void {
    this.user = temporaryUser;
  }
}
